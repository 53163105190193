<template>
  <div class="page">
    <Navbar title="常见问题" type="SMP" />
    <van-row>
      <van-col span="24" class="name">{{ issue.name }}</van-col>
    </van-row>
    <van-row class="van-hairline--bottom">
      <van-col span="18" class="score"
        >评分：<van-rate
          v-model="issue.score"
          color="#ffd21e"
          size="16"
          readonly
      /></van-col>
      <van-col span="6" class="read">阅读：{{ issue.read }}次</van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <div class="content" v-html="issue.content"></div>
      </van-col>
    </van-row>
    <loading :show="loadingShow" />
  </div>
</template>
<script>
import { Rate } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    [Rate.name]: Rate
  },
  data () {
    return {
      loadingShow: false,
      code: '',
      issue: { name: '', score: 100, read: 0, content: '' }
    }
  },
  mounted () {
    var query = this.$route.query
    this.code = query.code
    this.retrieveIssue()
  },
  methods: {
    async retrieveIssue () {
      var pd = { code: this.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/help/issue/readIssue', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.issue.name = res.data.name
        this.issue.read = res.data.read
        this.issue.content = res.data.content
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding: 40px 10px 0px 10px;
  .name {
    font-size: 18px;
    font-weight: 800;
    line-height: 35px;
    text-align: left;
  }
  .score {
    margin-bottom: 5px;
    text-align: left;
    line-height: 25px;
    color: #666;
  }
  .read {
    margin-bottom: 5px;
    text-align: left;
    line-height: 25px;
    color: #666;
  }
  .content {
    margin-top: 5px;
    text-align: left;
    line-height: 25px;
  }
}
</style>
